import React,{useEffect} from "react"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import "./datatables.scss"

const ProjectTableByAgents = (props) => {
 
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 50,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Mobile",
        field: "mobile",
        sort: "asc",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 200,
      },
      {
        label: "Project",
        field: "project",
        sort: "asc",
        width: 150,
      },
      {
        label: "City",
        field: "city",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        
        width: 10,
      },
    ],
    rows: [
      {
        id: 1,
        name: "Ahmed Khan",
        mobile: "0300-1234567",
        email: "ahmed.khan@example.com",
        project: "E-commerce Website",
        city: "Karachi",
        action: (
          <button className="btn btn-danger">
            <i className="mdi mdi-delete"></i>
          </button>
        ),
      },
      {
        id: 2,
        name: "Fatima Shaikh",
        mobile: "0301-7654321",
        email: "fatima.shaikh@example.com",
        project: "Mobile App Development",
        city: "Lahore",
        action: (
          <button className="btn btn-danger">
            <i className="mdi mdi-delete"></i>
          </button>
        ),
      },
      {
        id: 3,
        name: "Usman Ali",
        mobile: "0321-9876543",
        email: "usman.ali@example.com",
        project: "Digital Marketing",
        city: "Islamabad",
        action: (
          <button className="btn btn-danger">
            <i className="mdi mdi-delete"></i>
          </button>
        ),
      },
      {
        id: 4,
        name: "Sara Malik",
        mobile: "0345-6543210",
        email: "sara.malik@example.com",
        project: "SEO Optimization",
        city: "Peshawar",
        action: (
          <button className="btn btn-danger">
            <i className="mdi mdi-delete"></i>
          </button>
        ),
      },
      {
        id: 5,
        name: "Bilal Ahmad",
        mobile: "0333-4567890",
        email: "bilal.ahmad@example.com",
        project: "Web Hosting",
        city: "Faisalabad",
        action: (
          <button className="btn btn-danger">
            <i className="mdi mdi-delete"></i>
          </button>
        ),
      },
      {
        id: 6,
        name: "Maryam Khan",
        mobile: "0302-1230987",
        email: "maryam.khan@example.com",
        project: "Cloud Computing",
        city: "Rawalpindi",
        action: (
          <button className="btn btn-danger">
            <i className="mdi mdi-delete"></i>
          </button>
        ),
      },
      {
        id: 7,
        name: "Hassan Raza",
        mobile: "0308-8765432",
        email: "hassan.raza@example.com",
        project: "AI Development",
        city: "Multan",
        action: (
          <button className="btn btn-danger">
            <i className="mdi mdi-delete"></i>
          </button>
        ),
      },
      {
        id: 8,
        name: "Khadija Ahmed",
        mobile: "0322-2345678",
        email: "khadija.ahmed@example.com",
        project: "Data Analysis",
        city: "Quetta",
        action: (
          <button className="btn btn-danger">
            <i className="mdi mdi-delete"></i>
          </button>
        ),
      },
      {
        id: 9,
        name: "Nadia Zafar",
        mobile: "0341-6789012",
        email: "nadia.zafar@example.com",
        project: "Cyber Security",
        city: "Sialkot",
        action: (
          <button className="btn btn-danger">
            <i className="mdi mdi-delete"></i>
          </button>
        ),
      },
      {
        id: 10,
        name: "Ali Javed",
        mobile: "0307-3456789",
        email: "ali.javed@example.com",
        project: "Blockchain",
        city: "Hyderabad",
        action: (
          <button className="btn btn-danger">
            <i className="mdi mdi-delete"></i>
          </button>
        ),
      },
    ],
  };
  

  return (
    <React.Fragment>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">Agents Working on Projects </CardTitle>

                  <MDBDataTable responsive striped bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(ProjectTableByAgents);
import React, { useState } from 'react';
import { MDBDataTable } from 'mdbreact';
import { Card, CardBody, Label, FormGroup, Input, Button } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";

const LeadAssign = () => {
    const [leadDistributionMethod, setLeadDistributionMethod] = useState('');
    const [assignMethod, setAssignMethod] = useState('');
    const [campaignName, setCampaignName] = useState('');
    const [campaignDate, setCampaignDate] = useState(new Date());
    const [campaignTime, setCampaignTime] = useState('');
    const [campaignPercentage, setCampaignPercentage] = useState('');

    const handleLeadDistributionChange = (event) => {
        setLeadDistributionMethod(event.target.value);
        setAssignMethod(''); // Reset assign method when lead distribution method changes
    };

    const handleAssignMethodChange = (event) => {
        setAssignMethod(event.target.value);
    };

    const manualLeadData = {
        columns: [
            { label: '#', field: 'id', sort: 'asc', width: 30 },
            { label: 'Opportunity', field: 'opportunity', sort: 'asc', width: 150 },
            { label: 'Client', field: 'client', sort: 'asc', width: 160 },
            { label: 'City', field: 'city', sort: 'asc', width: 150 },
            { label: 'Country', field: 'country', sort: 'asc', width: 150 },
            { label: 'Phone', field: 'phone', sort: 'asc', width: 150 },
            { label: 'Action', field: 'action', sort: 'asc', width: 150 },
        ],
        rows: [
            { id: 1, opportunity: 'New Deal', client: 'John Doe', city: 'New York', country: 'USA', phone: '+1234567890', action: 'Follow Up' },
            // Add more rows as needed
        ],
    };

    const artMethodData = {
        columns: [
            { label: '#', field: 'id', sort: 'asc', width: 50 },
            { label: 'Name', field: 'name', sort: 'asc', width: 150 },
            { label: 'Date', field: 'date', sort: 'asc', width: 150 },
            { label: 'Time', field: 'time', sort: 'asc', width: 150 },
            { label: 'Percentage', field: 'percentage', sort: 'asc', width: 150 },
            { label: 'Status', field: 'status', sort: 'asc', width: 150 },
        ],
        rows: [
            { id: 1, name: 'Jane Doe', date: '2024-08-21', time: '01:45 PM', percentage: '90%', status: 'Completed' },
            // Add more rows as needed
        ],
    };

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <FormGroup>
                        <Label htmlFor="leadDistribution">Select Lead Distribution Method:</Label>
                        <Input type="select" id="leadDistribution" value={leadDistributionMethod} onChange={handleLeadDistributionChange}>
                            <option value="">Select</option>
                            <option value="manual">Manual</option>
                            <option value="automatic">Automatic</option>
                        </Input>
                    </FormGroup>
                </CardBody>
            </Card>

            {/* Conditional Rendering based on Lead Distribution Method */}
            {leadDistributionMethod === 'manual' && (
                <Card>
                    <CardBody>
                        <h3>Manual Lead Assignment</h3>
                        <MDBDataTable
                            striped
                            bordered
                            hover
                            data={manualLeadData}
                        />
                    </CardBody>
                </Card>
            )}

            {leadDistributionMethod === 'automatic' && (
                <Card>
                    <CardBody>
                        <FormGroup>
                            <Label htmlFor="assignMethod">Assign Method:</Label>
                            <Input type="select" id="assignMethod" value={assignMethod} onChange={handleAssignMethodChange}>
                                <option value="">Select</option>
                                <option value="artMethod">ART Method</option>
                                <option value="method2">Method 2</option>
                            </Input>
                        </FormGroup>

                        {assignMethod === 'artMethod' && (
                            <div>
                                <h4>Average Response Time</h4>


                                {/* Input Fields Below Average Response Time */}

                                <div className='d-flex justify-content-evenly flex-wrap mb-4'>
                                    <div className='col-md-2 col-sm-5 '><FormGroup>
                                        <Label for="campaignName">Campaign Name</Label>
                                        <Input
                                            type="text"
                                            id="campaignName"
                                            value={campaignName}
                                            onChange={(e) => setCampaignName(e.target.value)}
                                            placeholder="Campaign Name..."
                                        />
                                    </FormGroup></div>
                                    <div className='col-md-3 col-sm-5'> <FormGroup>
                                        <Label for="campaignDate">Campaign Date</Label>
                                        <Flatpickr
                                            className="form-control d-block"
                                            id="campaignDate"
                                            value={campaignDate}
                                            onChange={(date) => setCampaignDate(date)}
                                            options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d",
                                            }}
                                        />
                                    </FormGroup></div>
                                    <div className='col-md-3 col-sm-5'>   <FormGroup>
                                        <Label for="campaignTime">Campaign Time (Hours)</Label>
                                        <Input
                                            type="text"
                                            id="campaignTime"
                                            value={campaignTime}
                                            onChange={(e) => setCampaignTime(e.target.value)}
                                            placeholder="Campaign Time..."
                                        />
                                    </FormGroup>
                                    </div>
                                    <div className='col-md-3 col-sm-5'>
                                        <FormGroup>
                                            <Label for="campaignPercentage">Campaign Percentage</Label>
                                            <Input
                                                type="text"
                                                id="campaignPercentage"
                                                value={campaignPercentage}
                                                onChange={(e) => setCampaignPercentage(e.target.value)}
                                                placeholder="Campaign Percentage..."
                                            />
                                        </FormGroup>
                                        <div>
                                        </div>
                                        <Button color="primary" className="waves-effect waves-light w-100">Submit</Button>
                                    </div>
                                </div>



                                <hr className='mb-3'/>
                                <h4>All Campaigns</h4>

                                <MDBDataTable
                                    striped
                                    bordered
                                    hover
                                    data={artMethodData}
                                />

                            </div>
                        )}
                    </CardBody>
                </Card>
            )}
        </React.Fragment>
    );
};

export default LeadAssign;

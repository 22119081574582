import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Row, Col, Card, CardBody, CardTitle, CardText, Button} from 'reactstrap' ;
import FloorDetailsTable from './FloorDetailsTable';

export default function ProjectDetail() {
  const { project_id } = useParams(); 
  const [project, setProject] = useState({});
  const [images, setImages] = useState([]);
  const [status, setStatus] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const projectResponse = await axios.get(`http://localhost:5000/project/${project_id}`);
        const projectData = projectResponse.data;
        setProject(projectData);
        setImages(projectData.images || []);
        setStatus(projectData.project_status === 1 ? 'Active' : 'Completed');
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };

    fetchData();
  }, [project_id]);

  return (
    <div className="container ">
      <h2 className="mb-4">Project Details</h2>
      <Card>
        <Row >
          <Col md="4">
            {project.project_logo && (
              <img
                src={`http://localhost:5000/uploads/project/logo/${project.project_logo}`}
                className="img-fluid rounded-start"
                alt="Project Logo"
                style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
              />
            )}
            {images.length > 0 && (
              <div className="mt-3">
                {images.map((img, index) => (
                  <img
                    key={index}
                    src={`http://localhost:5000/uploads/project/${img.project_images_name}`}
                    className="img-fluid mb-2"
                    alt={`Project ${index + 1}`}
                    style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                  />
                ))}
              </div>
            )}
          </Col>
          <Col md="8">
            <CardBody>
              <CardTitle tag="h4">{project.project_name}</CardTitle>
              <CardText>
                <strong>Business Type:</strong> {project.project_business_type}
              </CardText>
              <CardText>
                <strong>Type:</strong> {project.project_type}
              </CardText>
              <CardText>
                <strong>City:</strong> {project.project_city}
              </CardText>
              <CardText>
                <strong>Area:</strong> {project.project_area}
              </CardText>
              <CardText>
                <strong>Floors:</strong> {project.project_floors}
              </CardText>
              <CardText>
                <strong>Status:</strong> {status}
              </CardText>
              <CardText>
                <strong>Created Time:</strong> {new Date(project.project_timestamp).toLocaleDateString()}
              </CardText>
              <Button color="primary" href={`/update-project/${project_id}`}>Edit Detail</Button>
            </CardBody>
          </Col>
        </Row>
      </Card>

      <Card className='mt-3'>
      <FloorDetailsTable  />
      </Card>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Card, CardBody, Col, Row, FormGroup, Form, Input, Button } from "reactstrap";
import { useParams, Link } from 'react-router-dom';
import { setBreadcrumbItems } from "../../store/actions";

const EditFloorDetail = (props) => {
    document.title = "Edit Floor | ConnectVU ";

    const { floor_detail_id } = useParams();
    const [floorDetail, setFloorDetail] = useState({});
    const [breadcrumbItems] = useState([
        { title: "Home", link: "/" },
        { title: "Project", link: "#" },
        { title: "Edit Detail of Floor", link: "#" }
    ]);

    useEffect(() => {
        // Set breadcrumb items
        props.setBreadcrumbItems('Edit Floor', breadcrumbItems);

        // Fetch floor detail data
        const fetchFloorDetail = async () => {
            try {
                const response = await axios.get(`http://localhost:5000/floor-detail/${floor_detail_id}`);
                setFloorDetail(response.data);
            } catch (error) {
                console.error('Error fetching floor detail:', error);
            }
        };

        fetchFloorDetail();
    }, [floor_detail_id]);

    const handleCheckboxChange = (e) => {
        setFloorDetail({
            ...floorDetail,
            [e.target.name]: e.target.checked ? 1 : 0
        });
    };

    const handleInputChange = (e) => {
        setFloorDetail({
            ...floorDetail,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`http://localhost:5000/update-floor/${floor_detail_id}`, floorDetail);
            alert('Floor details updated successfully!');
        } catch (error) {
            console.error('Error updating floor details:', error);
            alert('Failed to update floor details.');
        }
    };

    return (
        <React.Fragment>
            <div className="pcoded-inner-content">
                <div className="main-body">
                    <div className="page-wrapper">
                        <Card>
                            <CardBody>
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                    <Col sm="6">
                                            <label>Floor Type *</label>
                                            <select
                                                className="form-control"
                                                name="floor_detail_type"
                                                value={floorDetail.floor_detail_type || ''}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="" disabled>Select Type</option>
                                                <option value="Commercial">Commercial</option>
                                                <option value="Residential">Residential</option>
                                            </select>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup>
                                                <label>Floor Name *</label>
                                                <Input
                                                    type="text"
                                                    name="floor_detail_name"
                                                    value={floorDetail.floor_detail_name || ''}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <h5>Commercial Type *</h5>
                                    <Row>
                                        <Col sm="6">
                                            <FormGroup check>
                                                <Input
                                                    type="checkbox"
                                                    name="floor_detail_kiosk"
                                                    checked={!!floorDetail.floor_detail_kiosk}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label>Kiosk</label>
                                                {floorDetail.floor_detail_kiosk ? (
                                                    <Input
                                                        type="number"
                                                        name="kiosk_quantity"
                                                        value={floorDetail.kiosk_quantity || ''}
                                                        onChange={handleInputChange}
                                                        placeholder="Quantity"
                                                    />
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup check>
                                                <Input
                                                    type="checkbox"
                                                    name="floor_detail_shops"
                                                    checked={!!floorDetail.floor_detail_shops}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label>Shops</label>
                                                {floorDetail.floor_detail_shops ? (
                                                    <Input
                                                        type="number"
                                                        name="shops_quantity"
                                                        value={floorDetail.shops_quantity || ''}
                                                        onChange={handleInputChange}
                                                        placeholder="Quantity"
                                                    />
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup check>
                                                <Input
                                                    type="checkbox"
                                                    name="floor_detail_offices"
                                                    checked={!!floorDetail.floor_detail_offices}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label>Offices</label>
                                                {floorDetail.floor_detail_offices ? (
                                                    <Input
                                                        type="number"
                                                        name="offices_quantity"
                                                        value={floorDetail.offices_quantity || ''}
                                                        onChange={handleInputChange}
                                                        placeholder="Quantity"
                                                    />
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup check>
                                                <Input
                                                    type="checkbox"
                                                    name="floor_detail_hotel_apartment"
                                                    checked={!!floorDetail.floor_detail_hotel_apartment}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label>Hotel Apartment</label>
                                                {floorDetail.floor_detail_hotel_apartment ? (
                                                    <Input
                                                        type="number"
                                                        name="hotel_apartment_quantity"
                                                        value={floorDetail.hotel_apartment_quantity || ''}
                                                        onChange={handleInputChange}
                                                        placeholder="Quantity"
                                                    />
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup check>
                                                <Input
                                                    type="checkbox"
                                                    name="floor_detail_service_apartment"
                                                    checked={!!floorDetail.floor_detail_service_apartment}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label>Service Apartment</label>
                                                {floorDetail.floor_detail_service_apartment ? (
                                                    <Input
                                                        type="number"
                                                        name="service_apartment_quantity"
                                                        value={floorDetail.service_apartment_quantity || ''}
                                                        onChange={handleInputChange}
                                                        placeholder="Quantity"
                                                    />
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup check>
                                                <Input
                                                    type="checkbox"
                                                    name="floor_detail_hs_apartment"
                                                    checked={!!floorDetail.floor_detail_hs_apartment}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label>Hotel Service Apartment</label>
                                                {floorDetail.floor_detail_hs_apartment ? (
                                                    <Input
                                                        type="number"
                                                        name="hs_apartment_quantity"
                                                        value={floorDetail.hs_apartment_quantity || ''}
                                                        onChange={handleInputChange}
                                                        placeholder="Quantity"
                                                    />
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <div className="text-center">
                                        <Button type="submit" color="success">Submit</Button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default connect(null, { setBreadcrumbItems })(EditFloorDetail);

import React,{useEffect} from "react"

import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import PricingCard from "./PricingCard";

const Subscription = (props) => {
    document.title = "Pricing | ConnectVU ";

    const breadcrumbItems = [
        { title: "Dashboard", link: "/dashboard" },
        { title: "Subscriptions", link: "#" },
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Pricing & Subscription', breadcrumbItems)
    })

    return (
        <React.Fragment>
            <PricingCard />
        </React.Fragment>
    )
}

export default connect(null, { setBreadcrumbItems })(Subscription);
import React, { useEffect, useState } from 'react';
import { Card, CardBody, Label, FormGroup, Input, Button, Row, Col } from 'reactstrap';
import { connect } from "react-redux";
import EmployeesCards from './EmployeesCards';
import { setBreadcrumbItems } from "../../../store/actions";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './EmployeesCards.css'; // Create this CSS file to include your animation styles
import { Link } from 'react-router-dom';

const ViewEmployees = (props) => {
    document.title = "Employees | ConnectVU ";

    const breadcrumbItems = [
        { title: "ConnectVU", link: "#" },
        { title: "HR", link: "#" },
        { title: "Employees", link: "#" },
        { title: "View Employees", link: "#" },
    ];

    useEffect(() => {
        props.setBreadcrumbItems('View Employees', breadcrumbItems);
    });

    const [filter, setFilter] = useState("All");

    const users = [
        {
            id: 1,
            imgUrl: "https://media.licdn.com/dms/image/v2/D4D03AQHlg7BRFabzKw/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1721204807103?e=1730332800&v=beta&t=N8c2qjzb5eNud5evG-_5Jsse8BLnVXV7M-IIKCG37rc",
            name: "Farhad Nazir",
            company: "connectvu.com",
            designation: "Developer",
            email: "farhad.nazir@connectvu.com",
            phone: "0300-1234567",
            emergency: "0300-7654321",
            address: "House # 1, Street # 8G, Kehkashan Colony Adyala Road RWP",
            department: "IT & Development"
        },
        {
            id: 2,
            imgUrl: "https://randomuser.me/api/portraits/men/1.jpg",
            name: "John Doe",
            company: "example.com",
            designation: "Manager",
            email: "john.doe@example.com",
            phone: "0311-2345678",
            emergency: "0311-8765432",
            address: "Flat 12B, Main Street, Downtown City",
            department: "Executive"
        },
        {
            id: 3,
            imgUrl: "https://randomuser.me/api/portraits/women/2.jpg",
            name: "Jane Smith",
            company: "example.org",
            designation: "Designer",
            email: "jane.smith@example.org",
            phone: "0322-3456789",
            emergency: "0322-9876543",
            address: "Apartment 34C, Elm Street, Smalltown",
            department: "Admin"
        },
        {
            id: 4,
            imgUrl: "https://randomuser.me/api/portraits/men/3.jpg",
            name: "Michael Johnson",
            company: "mywebsite.com",
            designation: "Analyst",
            email: "michael@mywebsite.com",
            phone: "0333-4567890",
            emergency: "0333-0987654",
            address: "Office 101, Tech Park, Silicon Valley",
            department: "Finance"
        },
        {
            id: 5,
            imgUrl: "https://randomuser.me/api/portraits/women/4.jpg",
            name: "Emily Davis",
            company: "business.net",
            designation: "Agent",
            email: "emily.davis@business.net",
            phone: "0344-5678901",
            emergency: "0344-1098765",
            address: "Suite 205, Innovation Center, Creative City",
            department: "Sales"
        }
    ];

    const filterUsers = (department) => {
        setFilter(department);
    };

    const filteredUsers = users.filter(user => filter === "All" || user.department === filter);

    return (
        <React.Fragment>
            <Row>
                <FormGroup>
                    <div className='d-flex justify-content-between'>
                        <div className='col-md-4 me-2 d-flex'>
                            <Input type="select">
                                <option value="">Select company</option>
                                <option value="new">Exytex</option>
                            </Input>
                            <Button color="primary" className='ms-2'>Search</Button>
                        </div>
                        <div className='col-2'>
                          <Link to={'/add-employee'}>  <Button color="primary" className='w-100'>Add Employee</Button></Link>
                        </div>
                    </div>
                    <hr />
                </FormGroup>

                <FormGroup>
                    <div className="d-flex flex-wrap mb-4 mx-3">
                        <Button color={filter === "All" ? "primary" : "secondary"} className="me-2 mb-2" onClick={() => filterUsers("All")}>All</Button>
                        <Button color={filter === "IT & Development" ? "primary" : "secondary"} className="me-2 mb-2" onClick={() => filterUsers("IT & Development")}>IT & Development</Button>
                        <Button color={filter === "Executive" ? "primary" : "secondary"} className="me-2 mb-2" onClick={() => filterUsers("Executive")}>Executive</Button>
                        <Button color={filter === "Admin" ? "primary" : "secondary"} className="me-2 mb-2" onClick={() => filterUsers("Admin")}>Admin</Button>
                        <Button color={filter === "Acquisition" ? "primary" : "secondary"} className="me-2 mb-2" onClick={() => filterUsers("Acquisition")}>Acquisition</Button>
                        <Button color={filter === "Finance" ? "primary" : "secondary"} className="me-2 mb-2" onClick={() => filterUsers("Finance")}>Finance</Button>
                        <Button color={filter === "Training & Development" ? "primary" : "secondary"} className="me-2 mb-2" onClick={() => filterUsers("Training & Development")}>Training & Development</Button>
                        <Button color={filter === "Human Resources" ? "primary" : "secondary"} className="me-2 mb-2" onClick={() => filterUsers("Human Resources")}>Human Resources</Button>
                        <Button color={filter === "Sales" ? "primary" : "secondary"} className="me-2 mb-2" onClick={() => filterUsers("Sales")}>Sales</Button>
                        <Button color={filter === "Marketing" ? "primary" : "secondary"} className="me-2 mb-2" onClick={() => filterUsers("Marketing")}>Marketing</Button>
                    </div>
                </FormGroup>

                <TransitionGroup className="d-flex flex-wrap">
                    {filteredUsers.map(user => (
                        <CSSTransition key={user.id} timeout={500} classNames="flip">
                            <EmployeesCards users={[user]} />
                        </CSSTransition>
                    ))}
                </TransitionGroup>
            </Row>
        </React.Fragment>
    );
}

export default connect(null, { setBreadcrumbItems })(ViewEmployees);

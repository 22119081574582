import React,{useEffect} from "react"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import "./datatables.scss"

const FbCampaignTable = (props) => {
 

    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                sort: "asc",
                width: 30,
            },
            {
                label: "Name",
                field: "name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Project",
                field: "project",
                sort: "asc",
                width: 200,
            },
            {
                label: "Campaign ID",
                field: "campaignId",
                sort: "asc",
                width: 200,
            },
            {
                label: "Action",
                field: "action",
                sort: "asc",
                width: 40,
            },
        ],
        rows: [
            {
                id: 1,
                name: "Ahmed Khan",
                project: "E-commerce Development",
                campaignId: "CAM12345",
                action: <button className="btn btn-danger"><i className="fa fa-trash"></i></button>,
            },
            {
                id: 2,
                name: "Fatima Shaikh",
                project: "Marketing Strategy",
                campaignId: "CAM67890",
                action: <button className="btn btn-danger"><i className="fa fa-trash"></i></button>,
            },
            {
                id: 3,
                name: "Usman Ali",
                project: "Mobile App Design",
                campaignId: "CAM11223",
                action: <button className="btn btn-danger"><i className="fa fa-trash"></i></button>,
            },
            {
                id: 4,
                name: "Sara Malik",
                project: "SEO Optimization",
                campaignId: "CAM44556",
                action: <button className="btn btn-danger"><i className="fa fa-trash"></i></button>,
            },
            {
                id: 5,
                name: "Zainab Qureshi",
                project: "Content Creation",
                campaignId: "CAM78901",
                action: <button className="btn btn-danger"><i className="fa fa-trash"></i></button>,
            },
            {
                id: 6,
                name: "Hassan Raza",
                project: "Product Launch",
                campaignId: "CAM23456",
                action: <button className="btn btn-danger"><i className="fa fa-trash"></i></button>,
            },
            {
                id: 7,
                name: "Ayesha Noor",
                project: "Social Media Campaign",
                campaignId: "CAM34567",
                action: <button className="btn btn-danger"><i className="fa fa-trash"></i></button>,
            },
            {
                id: 8,
                name: "Bilal Ahmad",
                project: "Market Research",
                campaignId: "CAM45678",
                action: <button className="btn btn-danger"><i className="fa fa-trash"></i></button>,
            },
            {
                id: 9,
                name: "Maryam Khan",
                project: "Brand Development",
                campaignId: "CAM56789",
                action: <button className="btn btn-danger"><i className="fa fa-trash"></i></button>,
            },
            {
                id: 10,
                name: "Imran Siddiqui",
                project: "UI/UX Design",
                campaignId: "CAM67891",
                action: <button className="btn btn-danger"><i className="fa fa-trash"></i></button>,
            },
        ],
    };
    

  return (
    <React.Fragment>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">Projects</CardTitle>

                  <MDBDataTable responsive striped bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(FbCampaignTable);
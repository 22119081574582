import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
} from "reactstrap"

import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

const ModalCreateFolder = (props) => {

  const breadcrumbItems = [
    { title: "ConnectVU", link: "#" },
    { title: "UI Elements", link: "#" },
    { title: "Modals", link: "#" },
  ]



  const [modal_standard, setmodal_standard] = useState(false)
  const [modal_large, setmodal_large] = useState(false)
  const [modal_small, setmodal_small] = useState(false)
  const [modal_center, setmodal_center] = useState(false)

  function tog_standard() {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_large() {
    setmodal_large(!modal_large)
    removeBodyCss()
  }

  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  return (
    <React.Fragment>

      <Row>
        <Col className="col-12">
          <Card>
            <button
              type="button"
              className="btn btn-primary waves-effect waves-light "
              onClick={() => {
                tog_center()
              }}
              data-toggle="modal"
              data-target=".bs-example-modal-center"
            >
              <i className="ion ion-md-add "></i>
            </button>
            <Modal
              isOpen={modal_center}
              toggle={() => {
                tog_center()
              }}
              centered={true}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0">Create A Folder</h5>
                <button
                  type="button"
                  onClick={() => {
                    setmodal_center(false)
                  }}
                  className="btn btn-danger"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Col lg={10} className="m-lg-auto">
                  <div className="mt-1">
                    <form>
                      <div className="row mb-2">
                      <div className="mb-1">
                        <label className="form-label" htmlFor="formrow-firstname-input">Folder name</label>
                        <input type="text" className="form-control" id="formrow-firstname-input" placeholder="Enter your folder name"/>
                      </div>
                      </div>
                      <div className="mb-3">
                      <label className="col-form-label">Select</label>
                        <div className="">
                          <select className="form-control">
                            <option>Select...</option>
                          </select>
                          <small className="mt-1">Select the parent folder where you want to create the folder.</small>
                        </div>
                          </div>
            


                      <div className="row justify-content-start">
                        <div className="col-sm-9">
                          <div>
                            <button type="submit" className="btn btn-primary w-md ">Submit <i className="mdi mdi-arrow-right  m-auto"></i></button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </Col>
              </div>
            </Modal>

          </Card>
        </Col>
      </Row>

    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(ModalCreateFolder);
import React from 'react';
import './PricingCard.css'
const PricingCard = () => {
    return (
        <div className="pricing-card">

            <div className="main">
                <table className="price-table">
                    <tbody>
                        <tr>
                            <td className="price-blank"></td>
                            <td className="price-blank"></td>
                            <td className="price-table-popular">Most popular</td>
                            <td className="price-blank"></td>
                        </tr>
                        <tr className="price-table-head">
                            <td></td>
                            <td>
                                Free
                                <br /><small style={{ fontSize: '12px', fontWeight: 400 }}>Starter plan</small>
                            </td>
                            <td>
                                Personal
                                <br /><small style={{ fontSize: '12px', fontWeight: 400 }}>Longer data retention</small>
                            </td>
                            <td className="green-width">
                                Pro
                                <br /><small style={{ fontSize: '12px', fontWeight: 400 }}>Our complete solution</small>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="price">
                                Free
                                <br />
                                <a href="#">Get started</a>
                            </td>
                            <td className="price">
                                €9/mo
                                <br />
                                <a href="#">Get started</a>
                            </td>
                            <td className="price">
                                €39/mo
                                <br />
                                <a href="#">Get started</a>
                            </td>
                        </tr>
                        <tr>
                            <td>Sites</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                        </tr>
                        <tr>
                            <td>Data Retention</td>
                            <td>30 Days</td>
                            <td>90 Days</td>
                            <td>180 Days</td>
                        </tr>
                        <tr>
                            <td>Chart Annotations</td>
                            <td><i className="fas fa-times"></i></td>
                            <td><i className="fas fa-check"></i></td>
                            <td><i className="fas fa-check"></i></td>
                        </tr>
                        <tr>
                            <td>Uptime Monitoring</td>
                            <td><i className="fas fa-times"></i></td>
                            <td><i className="fas fa-check"></i></td>
                            <td><i className="fas fa-check"></i></td>
                        </tr>
                        <tr>
                            <td>Weekly Reports</td>
                            <td><i className="fas fa-times"></i></td>
                            <td><i className="fas fa-check"></i></td>
                            <td><i className="fas fa-check"></i></td>
                        </tr>
                        <tr>
                            <td>Security Audit</td>
                            <td><i className="fas fa-times"></i></td>
                            <td><i className="fas fa-check"></i></td>
                            <td><i className="fas fa-check"></i></td>
                        </tr>
                        <tr>
                            <td>On-Demand Audit</td>
                            <td><i className="fas fa-times"></i></td>
                            <td><i className="fas fa-times"></i></td>
                            <td><i className="fas fa-check"></i></td>
                        </tr>
                        <tr>
                            <td>Priority Support</td>
                            <td><i className="fas fa-times"></i></td>
                            <td><i className="fas fa-times"></i></td>
                            <td><i className="fas fa-check"></i></td>
                        </tr>
                        <tr>
                            <td>Easy Billing + No Contracts</td>
                            <td><i className="fas fa-check"></i></td>
                            <td><i className="fas fa-check"></i></td>
                            <td><i className="fas fa-check"></i></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="price">
                                <a href="#">Get started</a>
                            </td>
                            <td className="price">
                                <a href="#">Get started</a>
                            </td>
                            <td className="price">
                                <a href="#">Get started</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default PricingCard;

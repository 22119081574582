import React, { useEffect } from 'react';
import { Card, CardBody, Label, FormGroup, Input, Button, Row } from 'reactstrap';


import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

//Import Components
import CardUser from './card-user';


const SalesTeam = (props) => {
    document.title = "Sales Team | ConnectVU ";

    const breadcrumbItems = [
        { title: "ConnectVU", link: "#" },
        { title: "Sales", link: "#" },
        { title: "Sales-team", link: "#" },
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Sales Team', breadcrumbItems)
    })
    const users = [
        {
            id: 1,
            imgUrl: "https://scontent.fisb6-2.fna.fbcdn.net/v/t39.30808-6/451409828_2126692614383381_918268567279236953_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=6ee11a&_nc_eui2=AeE36DEsCdiQo_3ahaWPJ5MjqlX07M2j6veqVfTszaPq97moh71BMxkGmFumGFg2f-iOq0JzQq3sm-LYhXZwe0pg&_nc_ohc=4-4KnghegMoQ7kNvgEljeI6&_nc_ht=scontent.fisb6-2.fna&oh=00_AYDbInk5cvQ3rxvmVSqqW4gaM4dwOfUgy1S7jHsTQVODDg&oe=66CCE854",
            name: "Farhad Nazir",
            company: "connectvu.com",
            designation: "Developer",
            email: "farhad.nazir@connectvu.com",
            phone: "0300-1234567",
            emergency: "0300-7654321",
            address: "House # 1, Street # 8G, Kehkashan Colony Adyala Road RWP"
        },
        {
            id: 2,
            imgUrl: "https://randomuser.me/api/portraits/men/1.jpg",
            name: "John Doe",
            company: "example.com",
            designation: "Manager",
            email: "john.doe@example.com",
            phone: "0311-2345678",
            emergency: "0311-8765432",
            address: "Flat 12B, Main Street, Downtown City"
        },
        {
            id: 3,
            imgUrl: "https://randomuser.me/api/portraits/women/2.jpg",
            name: "Jane Smith",
            company: "example.org",
            designation: "Designer",
            email: "jane.smith@example.org",
            phone: "0322-3456789",
            emergency: "0322-9876543",
            address: "Apartment 34C, Elm Street, Smalltown"
        },
        {
            id: 4,
            imgUrl: "https://randomuser.me/api/portraits/men/3.jpg",
            name: "Michael Johnson",
            company: "mywebsite.com",
            designation: "Analyst",
            email: "michael.johnson@mywebsite.com",
            phone: "0333-4567890",
            emergency: "0333-0987654",
            address: "Office 101, Tech Park, Silicon Valley"
        },
        {
            id: 5,
            imgUrl: "https://randomuser.me/api/portraits/women/4.jpg",
            name: "Emily Davis",
            company: "business.net",
            designation: "Agent",
            email: "emily.davis@business.net",
            phone: "0344-5678901",
            emergency: "0344-1098765",
            address: "Suite 205, Innovation Center, Creative City"
        }
    ];



    return (
        <React.Fragment>

            <Row>
                <Card>
                    <CardBody>
                        <FormGroup>
                            {/* <Label>Select Lead Distribution Method:</Label> */}
                            <div className='d-flex justify-content-start'>
                                <div className='col-md-4 me-2'>
                                    <Input type="select">
                                        <option value="">Select</option>
                                        <option value="new">New</option>
                                        <option value="today">Today</option>
                                        <option value="all">All</option>
                                        <option value="overdue">Overdue</option>
                                        <option value="todays_follow_up">Today's Follow Up</option>
                                        <option value="recent">Recent</option>
                                        <option value="accepted">Accepted</option>
                                        <option value="won">Won</option>
                                        <option value="lost">Lost</option>
                                        <option value="lead_quality">Lead Quality</option>
                                        <option value="call_task">Call Task</option>
                                        <option value="connected">Connected</option>
                                        <option value="twl">TWL</option>
                                        <option value="manual">Manual</option>
                                        <option value="automatic">Automatic</option>
                                    </Input>
                                </div>
                                <div className='col-md-4 me-2'>
                                    <Input type="select">
                                        <option value="fatima_rizwan">Fatima Rizwan</option>
                                        <option value="muhammad_hassan">Muhammad Hassan</option>
                                        <option value="zainab_ali">Zainab Ali</option>
                                        <option value="aisha_rahman">Aisha Rahman</option>
                                        <option value="omar_khan">Omar Khan</option>
                                        <option value="samina_begum">Samina Begum</option>
                                        <option value="bilal_ahmed">Bilal Ahmed</option>
                                        <option value="amna_saeed">Amna Saeed</option>
                                        <option value="muhammad_ibrahim">Muhammad Ibrahim</option>
                                        <option value="noor_jahan">Noor Jahan</option>
                                        <option value="ali_haider">Ali Haider</option>
                                        <option value="muhammad_fahad">Muhammad Fahad</option>
                                        <option value="usman_khalid">Usman Khalid</option>
                                        <option value="sarah_nadeem">Sarah Nadeem</option>
                                        <option value="yasir_shah">Yasir Shah</option>
                                        <option value="hafsa_ali">Hafsa Ali</option>
                                        <option value="rabia_khan">Rabia Khan</option>
                                        <option value="maria_naseem">Maria Naseem</option>

                                    </Input>

                                </div>
                                <div className='col-md-2'>
                                    <Button color="primary" >Search</Button>
                                </div>

                            </div>

                        </FormGroup>
                    </CardBody>
                </Card>

                <CardUser users={users} />
            </Row>
        </React.Fragment>
    );
}

export default connect(null, { setBreadcrumbItems })(SalesTeam);
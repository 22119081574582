import React,{useEffect} from "react"

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col,Row } from "reactstrap";
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import FbCampaignTable from "./FbCampaignTable";
import AddFbCampaign from "./AddFbCampaign";

const PagesBlank = (props) => {
    document.title = "Campaign | ConnectVU ";

    const breadcrumbItems = [
        { title: "ConnectVU", link: "#" },
        { title: "create-campaign", link: "#" },
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Create Campaign', breadcrumbItems)
    })

    return (
        <React.Fragment>
            <Col className="d-flex justify-content-end">
             <AddFbCampaign/>
             </Col>
            <FbCampaignTable/>
        </React.Fragment>
    )
}

export default connect(null, { setBreadcrumbItems })(PagesBlank);
import React,{useEffect} from "react"

import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

const Inventory = (props) => {
    document.title = "Inventory | ConnectVU ";

    const breadcrumbItems = [
        { title: "ConnectVU", link: "/" },
        { title: "Inventory", link: "#" },
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Inventory', breadcrumbItems)
    })

    return (
        <React.Fragment>
            
        </React.Fragment>
    )
}

export default connect(null, { setBreadcrumbItems })(Inventory);
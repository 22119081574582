import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Input, Label, FormText } from 'reactstrap';
import 'flatpickr/dist/themes/material_blue.css';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const ProjectEdit = () => {
    const { project_id } = useParams(); // Use useParams to get route parameters
    const [project, setProject] = useState({
        project_name: '',
        project_city: '',
        project_area: '',
        project_floors: '',
        project_business_type: '',
        project_type: '',
        project_logo: null,
        project_unit_type: '',
        project_token: '',
        project_cdp: '',
        project_pdp: '',
        project_price_range: '',
        project_installment: '',
        project_months: '',
        files: []
    });

    useEffect(() => {
        const fetchProject = async () => {
            try {
                const response = await axios.get(`/project/${project_id}`);
                setProject(response.data);
            } catch (error) {
                console.error('Error fetching project details:', error);
            }
        };
        fetchProject();
    }, [project_id]);

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        if (type === 'file') {
            setProject({ ...project, [name]: files[0] });
        } else {
            setProject({ ...project, [name]: value });
        }
    };

    const handleFileChange = (e) => {
        setProject({ ...project, files: e.target.files });
    };
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
    
        const formData = new FormData();
        Object.keys(project).forEach(key => {
            if (key === 'files') {
                for (let i = 0; i < project.files.length; i++) {
                    formData.append('files', project.files[i]);
                }
            } else {
                formData.append(key, project[key]);
            }
        });
    
        try {
            await axios.post('/project/update', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            alert('Project updated successfully');
        } catch (error) {
            console.error('Error updating project:', error);
        }
    };
    

    return (
        <div className="project-edit">
            <h4 style={{ color: 'black' }}>Project Details</h4>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label for="project_name">Project Name *</Label>
                    <Input
                        type="text"
                        id="project_name"
                        name="project_name"
                        value={project.project_name}
                        onChange={handleChange}
                        required
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="project_city">City *</Label>
                    <Input
                        type="select"
                        id="project_city"
                        name="project_city"
                        value={project.project_city}
                        onChange={handleChange}
                        required
                    >
                        <option value="Ziarat">Ziarat</option>
                        {/* Add other options here */}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="project_area">Area *</Label>
                    <Input
                        type="text"
                        id="project_area"
                        name="project_area"
                        value={project.project_area}
                        onChange={handleChange}
                        required
                    />
                </FormGroup>
                {project.project_floors && (
                    <FormGroup>
                        <Label for="project_floors">Floors *</Label>
                        <Input
                            type="text"
                            id="project_floors"
                            name="project_floors"
                            value={project.project_floors}
                            onChange={handleChange}
                            required
                        />
                    </FormGroup>
                )}
                <FormGroup>
                    <Label for="files">Multiple Images</Label>
                    <Input
                        type="file"
                        id="files"
                        name="files"
                        multiple
                        onChange={handleFileChange}
                    />
                    <FormText color="muted">
                        You can upload multiple images here.
                    </FormText>
                </FormGroup>
                <FormGroup>
                    <Label for="project_business_type">Business Type *</Label>
                    <Input
                        type="radio"
                        id="both"
                        name="project_business_type"
                        value="Both"
                        checked={project.project_business_type === 'Both'}
                        onChange={handleChange}
                    />
                    <Label for="both">Both</Label>
                    <Input
                        type="radio"
                        id="commercial"
                        name="project_business_type"
                        value="Commercial"
                        checked={project.project_business_type === 'Commercial'}
                        onChange={handleChange}
                    />
                    <Label for="commercial">Commercial</Label>
                    <Input
                        type="radio"
                        id="residential"
                        name="project_business_type"
                        value="Residential"
                        checked={project.project_business_type === 'Residential'}
                        onChange={handleChange}
                    />
                    <Label for="residential">Residential</Label>
                </FormGroup>
                <FormGroup>
                    <Label for="project_type">Type *</Label>
                    <Input
                        type="radio"
                        id="vertical"
                        name="project_type"
                        value="Vertical"
                        checked={project.project_type === 'Vertical'}
                        onChange={handleChange}
                    />
                    <Label for="vertical">Vertical</Label>
                    <Input
                        type="radio"
                        id="horizontal"
                        name="project_type"
                        value="Horizontal"
                        checked={project.project_type === 'Horizontal'}
                        onChange={handleChange}
                    />
                    <Label for="horizontal">Horizontal</Label>
                </FormGroup>
                <FormGroup>
                    <Label for="project_logo">Upload Logo</Label>
                    <Input
                        type="file"
                        id="project_logo"
                        name="project_logo"
                        onChange={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="project_unit_type">Unit Type</Label>
                    <Input
                        type="text"
                        id="project_unit_type"
                        name="project_unit_type"
                        value={project.project_unit_type}
                        onChange={handleChange}
                        placeholder="Farm Houses..."
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="project_token">Booking Info -> Token</Label>
                    <Input
                        type="number"
                        id="project_token"
                        name="project_token"
                        value={project.project_token}
                        onChange={handleChange}
                        placeholder="20000..."
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="project_cdp">Booking Info -> CDP</Label>
                    <Input
                        type="number"
                        id="project_cdp"
                        name="project_cdp"
                        value={project.project_cdp}
                        onChange={handleChange}
                        placeholder="30..."
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="project_pdp">Booking Info -> PDP</Label>
                    <Input
                        type="number"
                        id="project_pdp"
                        name="project_pdp"
                        value={project.project_pdp}
                        onChange={handleChange}
                        placeholder="10..."
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="project_price_range">Price Range</Label>
                    <Input
                        type="text"
                        id="project_price_range"
                        name="project_price_range"
                        value={project.project_price_range}
                        onChange={handleChange}
                        placeholder="PKR 1 - 4.22 Crore..."
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="project_installment">Installment / Month</Label>
                    <Input
                        type="text"
                        id="project_installment"
                        name="project_installment"
                        value={project.project_installment}
                        onChange={handleChange}
                        placeholder="PKR 8.53 Lakhs"
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="project_months">Total Months</Label>
                    <Input
                        type="number"
                        id="project_months"
                        name="project_months"
                        value={project.project_months}
                        onChange={handleChange}
                        placeholder="48..."
                    />
                </FormGroup>
                <Button type="submit" color="primary">Update Project</Button>
                <Button type="reset" color="default">Clear</Button>
            </Form>
        </div>
    );
};

export default ProjectEdit;

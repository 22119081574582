import React , {useEffect} from "react"

import { connect } from "react-redux";
import {
  Row,
  Col,
} from "reactstrap"

// Pages Components
import Miniwidget from "./Miniwidget"
import LatestOrders from "./latest-orders";
import { Table, Card, Badge, Button } from "reactstrap";
import { Link } from "react-router-dom";
import ModalCreateFolder from "./ModalCreateFolder";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

const Dashboard = (props) => {

  document.title = "Dashboard | ConnectVU ";


  const breadcrumbItems = [
    { title: "ConnectVU", link: "#" },
    { title: "Dashboard", link: "#" }
  ]

  useEffect(() => {
    props.setBreadcrumbItems('Dashboard' , breadcrumbItems)
  },)

  const reports = [
    { title: "Orders", iconClass: "cube-outline", total: "1,587", average: "+11%", badgecolor: "info" },
    { title: "Revenue", iconClass: "buffer", total: "$46,782", average: "-29%", badgecolor: "danger" },
    { title: "Average Price", iconClass: "tag-text-outline", total: "$15.9", average: "0%", badgecolor: "warning" },
    { title: "Product Sold", iconClass: "briefcase-check", total: "1890", average: "+89%", badgecolor: "info" },
  ]

  return (
    <React.Fragment>

      {/*mimi widgets */}
      <Miniwidget reports={reports} />
      <Row>
        <Col xl="3">
        <Card>
                    <div className="card-body">
                      <Row>
                        <Col className="col-9">
                        <h4 className="card-title mb-4">Folders  </h4>
                        </Col>
                        <Col className="col-3">
                        <ModalCreateFolder/>
                        </Col>

                         </Row>
                      <ul>
                        <li>
                          <Link to="">Home</Link>
                        </li>
                        <li>
                          <Link to="">Trash</Link>
                        </li>
                        <li>
                          
                        </li>
                      </ul>
                    </div>
                </Card>
               
          
        </Col>

        <Col xl="9">
          {/* latest orders */}
          <LatestOrders />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Dashboard);
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Card } from "reactstrap";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import axios from 'axios';
import "./Workflow.css"; // Assuming you have some custom styles in this file
import { useLocation } from 'react-router-dom';

const AppsList = (props) => {
  

  return (
    <React.Fragment>
      <Row className="mb-4">
        <Form inline className="w-100">
          <Input
            type="text"
            placeholder="Search for apps..."
            className="me-2"
            style={{ width: "100%" }}
          />
        </Form>
      </Row>

      <Row className="d-flex justify-content-center app-list-area mt-2">
        {apps.map((app) => (
          <Col key={app.id} md={2} xs={6} className="AppsList mx-1">
            <Card
              className="text-center AppsList-icon"
              onClick={app.onClick} // Add click handler here
              style={{ cursor: "pointer" }}
            >
              <img
                src={app.icon}
                alt={app.name}
                className="img-fluid p-3 mx-auto"
                style={{ height: "60px", width: "60px" }}
              />
              <h5 className="small">{app.name}</h5>
            </Card>
          </Col>
        ))}
      </Row>

     
    </React.Fragment>
  );
};

// Example app icons data (this remains the same)
const apps = [
  {
    id: 1,
    name: "X.com",
    icon: "https://seeklogo.com/images/T/twitter-x-logo-0339F999CF-seeklogo.com.png?v=638264860180000000",
    onClick: () => window.open("https://x.com", "_blank"), // Example for X.com
  },
  {
    id: 3,
    name: "WhatsApp",
    icon: "https://e7.pngegg.com/pngimages/368/1007/png-clipart-logo-whatsapp-scalable-graphics-icon-whatsapp-logo-telephone-call-logo-text-grass-thumbnail.png",
    onClick: () => window.open("https://web.whatsapp.com", "_blank"), // Example for WhatsApp
  }
];

export default connect(null, { setBreadcrumbItems })(AppsList);

import React, { useEffect } from "react"

import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import Trigger from "./Trigger";
import Action from "./Action";

const PagesBlank = (props) => {
    document.title = "Workflow | ConnectVU ";

    const breadcrumbItems = [
        { title: "Dashboard", link: "/dashboard" },
        { title: "Workflow", link: "" },
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Workflow', breadcrumbItems)
    })

    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="row justify-content-between">
                <div className="cd-timeline-block timeline-straight ">
                        <div className="cd-timeline-img bg-success">
                            <i className="mdi mdi-adjust"></i>
                        </div>
                    </div>
                    <div className="col-5">
                        <Trigger />
                    </div>
                    <div className="col-2 m-0 p-0  justify-content-center">
                   
                    </div>
                    <div className="col-5">
                        <Action />
                    </div>
                </div>
                
            </div>

        </React.Fragment>
    )
}

export default connect(null, { setBreadcrumbItems })(PagesBlank);
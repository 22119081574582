import React,{useEffect} from "react"

import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import ProjectTable from "./ProjectTable";
import { Link } from "react-router-dom";
import { Col,Row } from "reactstrap";
const AddProjects = (props) => {
    document.title = "Projects | ConnectVU ";

    const breadcrumbItems = [
        { title: "Dashboard", link: "/dashboard" },
        { title: "Projects", link: "#" },
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Projects', breadcrumbItems)
    })
    
    return (
        <React.Fragment>
             <Col className="d-flex justify-content-end">
             <Link to="/add-projects">
             <button
              type="button"
              className="btn btn-primary waves-effect waves-light  mb-2 "
              data-toggle="modal"
              data-target=".bs-example-modal-center"
            >
              Add Project 
              <i className="ion ion-md-add ms-2"></i>
            </button>
             </Link>
             </Col>
            <ProjectTable/>

        </React.Fragment>
    )
}

export default connect(null, { setBreadcrumbItems })(AddProjects);
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Row, Col, Card, CardBody, CardTitle, Button } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';

const FloorDetailsTable = (props) => {
  const { project_id } = useParams();
  const [floors, setFloors] = useState([]);
  const [projectType, setProjectType] = useState('');
  const [totalFloors, setTotalFloors] = useState(0);

  useEffect(() => {
    fetchFloors();
  }, [project_id]);

  const fetchFloors = async () => {
    try {
      const projectResponse = await axios.get(`http://localhost:5000/project/${project_id}`);
      const floorResponse = await axios.get(`http://localhost:5000/project-floors/${project_id}`);

      setProjectType(projectResponse.data.project_type);
      setTotalFloors(projectResponse.data.project_floors);
      setFloors(floorResponse.data);

      
  const data = {
    columns: [
      { label: 'Name', field: 'name', sort: 'asc', width: 150 },
      { label: 'Type', field: 'type', sort: 'asc', width: 150 },
      { label: 'Units', field: 'units', sort: 'asc', width: 100 },
      { label: 'Action', field: 'action', sort: 'asc', width: 100 },
    ],
    rows: floors.map(floor => ({
      name: floor.floor_detail_name,
      type: floor.floor_detail_type,
      units: calculateTotalUnits(floor),
      action: (
        <div>
          <Link className="text-primary" to={`/floor-edit/${floor.floor_detail_id}`} title="Edit Floor">
            <i className="fa fa-edit fa-lg"></i>
          </Link>
          &nbsp;&nbsp;
          <span
            className="text-danger"
            onClick={() => handleDelete(floor.floor_detail_id)}
            title="Delete Floor"
            style={{ cursor: 'pointer' }}
          >
            <i className="fa fa-trash fa-lg"></i>
          </span>
        </div>
      ),
    })),
  };


    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  // Utility function to calculate the total units for a floor (as defined earlier)
  const calculateTotalUnits = (floor) => {
      return (
          (floor.kiosk_quantity || 0) +
          (floor.shops_quantity || 0) +
          (floor.offices_quantity || 0) +
          (floor.hotel_apartment_quantity || 0) +
          (floor.service_apartment_quantity || 0) +
          (floor.hs_apartment_quantity || 0) +
          (floor.apartment_quantity || 0) +
          (floor.houses_quantity || 0) +
          (floor.villas_quantity || 0)
      );
  };
  
  

  const handleDelete = async (floor_detail_id) => {
    if (window.confirm("Are you sure you want to delete this floor detail?")) {
      try {
        const response = await axios.delete(`http://localhost:5000/delete-floor/${floor_detail_id}`);
    
        if (response.status === 200) {
          console.log('Floor detail deleted:', response.data.message);
          alert(response.data.message);
  
          setFloors(floors.filter(floor => floor.floor_detail_id !== floor_detail_id)); // Update local state
          
        } else {
          console.error('Error deleting floor detail:', response.data.message);
          alert(`Error: ${response.data.message}`);
        }
      } catch (error) {
        console.error('Request failed:', error);
        alert(`Error: ${error.message}`);
      }
    }
  };

  const data = {
    columns: [
      { label: 'Name', field: 'name', sort: 'asc', width: 150 },
      { label: 'Type', field: 'type', sort: 'asc', width: 150 },
      { label: 'Units', field: 'units', sort: 'asc', width: 100 },
      { label: 'Action', field: 'action', sort: 'asc', width: 100 },
    ],
    rows: floors.map(floor => ({
      name: floor.floor_detail_name,
      type: floor.floor_detail_type,
      units: calculateTotalUnits(floor),
      action: (
        <div>
          <Link className="text-primary" to={`/floor-edit/${floor.floor_detail_id}`} title="Edit Floor">
            <i className="fa fa-edit fa-lg"></i>
          </Link>
          &nbsp;&nbsp;
          <span
            className="text-danger"
            onClick={() => handleDelete(floor.floor_detail_id)}
            title="Delete Floor"
            style={{ cursor: 'pointer' }}
          >
            <i className="fa fa-trash fa-lg"></i>
          </span>
        </div>
      ),
    })),
  };

  return (
    <Col lg="12">
      <Card>
        <CardBody>
          <CardTitle className="h4">
            {projectType !== 'Horizontal' ? 'Floor' : 'Area'} Details
          </CardTitle>
          {totalFloors !== floors.length && (
            // <Button color="primary" style={{ float: 'right' }} tag={Link} to={`/project-floor-add/${project_id}`}>
            //   Add Remaining Floors Detail
            // </Button>
            <>
            </>
          )}
          <hr />

          <MDBDataTable
            responsive
            striped
            bordered
            data={data}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

export default FloorDetailsTable;

import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"


// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Register1 from "../pages/AuthenticationInner/Register"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"

// Dashboard
import Dashboard from "../pages/Dashboard/index"


//Extra Pages
import PagesBlank from "../pages/Extra Pages/pages-blank";
import Pages404 from "../pages/Extra Pages/pages-404";
import Pages500 from "../pages/Extra Pages/pages-500";
import Workflow from "pages/Workflow"
import Subscription from "pages/Subscription"
import ViewProjects from "pages/Projects/ViewProjects"
import AddProjects from "pages/Projects/AddProjects"
import Marketing from "pages/Marketing/CampaignDistribution"
import CreateCampaign from "pages/Marketing/CreateCampaign"
import AssignLeads from "pages/Marketing/AssignLeads"
import ViewClients from "pages/Marketing/ViewClients"
import AddClient from "pages/Marketing/AddClient"
import SalesTeam from "pages/Sales/TeamManagementSales"
import ViewEmployees from "pages/HR/Employee/ViewEmployees"
import AddEmployee from "pages/HR/Employee/AddEmployee"
import EmployeeManagement from "pages/HR/Employee/EmployeeManagement"
import Inventory from "pages/Inventory/Inventory"
import ProjectEdit from "pages/Projects/ProjectEdit"
import ProjectDetails from "pages/Projects/ProjectDetails"
import EditFloorDetail from "pages/Projects/EditFloorDetail"


const userRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/subscription", component: <Subscription /> },
  { path: "/view-projects", component: <ViewProjects /> },
  { path: "/add-projects", component: <AddProjects /> },
  { path: "/campaign-distribution", component: <Marketing /> },
  { path: "/create-campaign", component: <CreateCampaign /> },
  { path: "/assign-leads", component: <AssignLeads /> },
  { path: "/view-clients", component: <ViewClients /> },
  { path: "/add-client", component: <AddClient /> },
  { path: "/sales-team-management", component: <SalesTeam/> },
  { path: "/view-employees", component: <ViewEmployees /> },
  { path:"/add-employee", component: <AddEmployee/>},
  { path: "/employee-management", component: <EmployeeManagement /> },

  // // //profile
  { path: "/profile", component: <UserProfile /> },

  { path: "/pages-blank", component: <PagesBlank /> },
  { path: "/workflow", component: <Workflow /> },
  { path: "/inventory", component: <Inventory /> },
  { path: "/project-edit", component: <ProjectEdit /> },
  { path: "/project-detail/:project_id", component: <ProjectDetails  /> },
  { path: "/floor-edit/:floor_detail_id", component: <EditFloorDetail  /> },



  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },

  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },

  // Authentication Inner
  { path: "/pages-login", component: <Login1 /> },
  { path: "/pages-register", component: <Register1 /> },
  { path: "/page-recoverpw", component: <Recoverpw /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },


  //Projects Routes
  // { path: "/project-create", component: <ProjectCreate /> },
  // { path: "/project-list", component: <ProjectList /> },

]

export { userRoutes, authRoutes }
import React, { useEffect, useState } from 'react';
import { MDBDataTable } from 'mdbreact';
import { Card, CardBody } from 'reactstrap';
import { setBreadcrumbItems } from 'store/actions';
import { connect } from 'react-redux';
import axios from 'axios';

const ViewClients = (props) => {
    document.title = "Clients | ConnectVU";

    const breadcrumbItems = [
        { title: "ConnectVU", link: "#" },
        { title: "view-clients", link: "#" },
    ];

    const [clientsData, setClientsData] = useState({ columns: [], rows: [] });

    useEffect(() => {
        props.setBreadcrumbItems('View Clients', breadcrumbItems);

        // Fetch data from the API
        axios.get('http://localhost:5000/clients')
            .then(response => {
                const data = response.data;
                const formattedData = {
                    columns: [
                        { label: '#', field: 'client_id', sort: 'asc', width: 30 },
                        { label: 'Name', field: 'client_name', sort: 'asc', width: 150 },
                        { label: 'Mobile', field: 'client_mobile', sort: 'asc', width: 150 },
                        { label: 'Email', field: 'client_email', sort: 'asc', width: 500 },
                        { label: 'Source', field: 'client_source', sort: 'asc', width: 150 },
                        { label: 'Referral', field: 'client_referral', sort: 'asc', width: 150 },
                        { label: 'Remarks', field: 'client_remarks', sort: 'asc', width: 250 },
                    ],
                    rows: data.map(client => ({
                        client_id: client.client_id,
                        client_name: client.client_name,
                        client_mobile: client.client_mobile,
                        client_email: client.client_email,
                        client_source: client.client_source,
                        client_referral: client.client_referral,
                        client_remarks: client.client_remarks,
                    })),
                };
                setClientsData(formattedData);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [props, breadcrumbItems]);

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <h3>View Clients</h3>
                    <MDBDataTable
                        striped
                        bordered
                        hover
                        data={clientsData}
                    />
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default connect(null, { setBreadcrumbItems })(ViewClients);

import React, { useEffect, useState } from "react"

import Switch from "react-switch"

import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import makeAnimated from "react-select/animated"
import Select from "react-select"
import { Button, Table, Card, CardBody, Col, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap"
import ProjectTableByAgents from "./ProjectTableByAgents";

const CampaignDistribution = (props) => {
    document.title = "Campaign Distribution | ConnectVU ";

    const Offsymbol = (props) => {

        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {" "}

            </div>
        )
    }

    const OnSymbol = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {" "}

            </div>
        )
    }

    const breadcrumbItems = [
        { title: "Dashboard", link: "#" },
        { title: "Marketing", link: "#" },
        { title: "Campaign Distribution", link: "#" },
    ]
    const optionGroup = [
        {
            label: "Sales Person",
            options: [
                { label: "Ahmed Khan", value: "ahmed_khan" },
                { label: "Fatima Shaikh", value: "fatima_shaikh" },
                { label: "Usman Ali", value: "usman_ali" },
                { label: "Sara Malik", value: "sara_malik" },
                { label: "Zainab Qureshi", value: "zainab_qureshi" },
                { label: "Hassan Raza", value: "hassan_raza" },
                { label: "Ayesha Noor", value: "ayesha_noor" },
                { label: "Bilal Ahmad", value: "bilal_ahmad" },
                { label: "Maryam Khan", value: "maryam_khan" },
                { label: "Imran Siddiqui", value: "imran_siddiqui" },
                { label: "Nadia Zafar", value: "nadia_zafar" },
                { label: "Raza Shah", value: "raza_shah" },
                { label: "Khadija Ahmed", value: "khadija_ahmed" },
                { label: "Ali Javed", value: "ali_javed" },
                { label: "Maham Iqbal", value: "maham_iqbal" }
            ]
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Campaign Average Response Time', breadcrumbItems)
    })
    const [selectedGroup, setselectedGroup] = useState(null)
    const [switch1, setswitch1] = useState(true)

    function handleSelectGroup(selectedGroup) {
        setselectedGroup(selectedGroup)
    }
    return (
        <React.Fragment>
            <Card >
                <CardBody>
                    <form>
                        <div className="mb-3">
                            <Label>Single Select</Label>
                            <Select
                                value={selectedGroup}
                                onChange={() => {
                                    handleSelectGroup()
                                }}
                                options={optionGroup}
                                classNamePrefix="select2-selection"
                            />
                        </div>
                    </form>
                </CardBody>
            </Card>
            <Card >
                <CardBody>
                    <div>
                        <Label>All Projects Details</Label>
                        <div className="table-responsive">
                            <Table className="table table-hover mb-0">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Project Name</th>
                                        <th>Filter</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">1</th>
                                        <td>Project 1</td>
                                        <td><Switch
                                            uncheckedIcon={<Offsymbol />}
                                            checkedIcon={<OnSymbol />}
                                            onColor="#626ed4"
                                            onChange={() => {
                                                setswitch1(!switch1)
                                            }}
                                            checked={switch1}
                                        /></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">2</th>
                                        <td>Project 2</td>
                                        <td><Switch
                                            uncheckedIcon={<Offsymbol />}
                                            checkedIcon={<OnSymbol />}
                                            onColor="#626ed4"
                                            onChange={() => {
                                                setswitch1(!switch1)
                                            }}
                                            checked={switch1}
                                        /></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">3</th>
                                        <td>Project 3</td>
                                        <td><Switch
                                            uncheckedIcon={<Offsymbol />}
                                            checkedIcon={<OnSymbol />}
                                            onColor="#626ed4"
                                            onChange={() => {
                                                setswitch1(!switch1)
                                            }}
                                            checked={switch1}
                                        /></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mx-0 my-3">
                        <Button type="button" outline color="primary" className="waves-effect waves-light col-2">Update</Button>
                    </div>
                    <div>
                    <ProjectTableByAgents/>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default connect(null, { setBreadcrumbItems })(CampaignDistribution);
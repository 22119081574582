import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Card } from "reactstrap";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import axios from 'axios';
import "./Workflow.css"; // Assuming you have some custom styles in this file
import { useLocation } from 'react-router-dom';

const AppsList = (props) => {
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState(null);

  // Helper to parse query parameters
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const sessionID = query.get('sessionID'); // Get session ID from URL

  // Fetch profile from backend and store in localStorage
  const fetchProfile = async () => {
    try {
      const response = await axios.get(`https://connect.hdjump.com/profile-info?sessionID=${sessionID}`, {
        withCredentials: true // Ensure credentials (cookies) are sent
      });
      const userProfile = response.data.user;
      // Store profile and access token in localStorage
      localStorage.setItem('profile', JSON.stringify(userProfile));
      localStorage.setItem('accessToken', userProfile.accessToken);
      setProfile(userProfile); // Set profile in component state
    } catch (err) {
      setError('Error fetching profile information.');
      console.error(err);
    }
  };

  // Check if profile data is available in localStorage on component mount
  useEffect(() => {
    const storedProfile = localStorage.getItem('profile');
    const storedAccessToken = localStorage.getItem('accessToken');

    if (storedProfile && storedAccessToken) {
      setProfile(JSON.parse(storedProfile)); // Load profile from localStorage
    } else if (sessionID) {
      fetchProfile(); // Fetch profile from backend if sessionID is available in URL
    } else {
      setError('No user information found. Please log in.');
    }
  }, [sessionID]);

  // const handleFacebookLogin = () => {
  //   window.location.href = "http://connect.hdjump.com/login/fb"; // Redirect to Facebook login
  // };

  return (
    <React.Fragment>
      <Row className="mb-4">
        <Form inline className="w-100">
          <Input
            type="text"
            placeholder="Search for apps..."
            className="me-2"
            style={{ width: "100%" }}
          />
        </Form>
      </Row>

      <Row className="d-flex justify-content-center app-list-area mt-2">
        {apps.map((app) => (
          <Col key={app.id} md={2} xs={6} className="AppsList mx-1">
            <Card
              className="text-center AppsList-icon"
              onClick={app.onClick} // Add click handler here
              style={{ cursor: "pointer" }}
            >
              <img
                src={app.icon}
                alt={app.name}
                className="img-fluid p-3 mx-auto"
                style={{ height: "60px", width: "60px" }}
              />
              <h5 className="small">{app.name}</h5>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Display the profile information in a separate section if the user is logged in */}
      {profile && (
        <div className="profile-info">
          <h1>Welcome, {profile.displayName}</h1>
          <p><strong>User ID:</strong> {profile.id}</p>
          <p><strong>Access Token:</strong> {localStorage.getItem('accessToken')}</p>
        </div>
      )}

      {error && <p>{error}</p>}
    </React.Fragment>
  );
};

// Example app icons data (this remains the same)
const apps = [
  {
    id: 1,
    name: "X.com",
    icon: "https://seeklogo.com/images/T/twitter-x-logo-0339F999CF-seeklogo.com.png?v=638264860180000000",
    onClick: () => window.open("https://x.com", "_blank"), // Example for X.com
  },
  { 
    id: 2, 
    name: "Facebook", 
    icon: "https://freepnglogo.com/images/all_img/facebook-circle-logo-png.png",
    onClick: () => {
      // Redirect to your backend Facebook login endpoint
      window.location.href = "https://connect.hdjump.com/login/fb";
    }
  },
  {
    id: 3,
    name: "WhatsApp",
    icon: "https://e7.pngegg.com/pngimages/368/1007/png-clipart-logo-whatsapp-scalable-graphics-icon-whatsapp-logo-telephone-call-logo-text-grass-thumbnail.png",
    onClick: () => window.open("https://web.whatsapp.com", "_blank"), // Example for WhatsApp
  }
];

export default connect(null, { setBreadcrumbItems })(AppsList);

import React, { useEffect, useState } from "react";
import axios from 'axios';
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import { Link } from 'react-router-dom';
import "./datatables.scss";

const ProjectTables = (props) => {
  const [data, setData] = useState({ columns: [], rows: [] });
  const [availableUnits, setAvailableUnits] = useState({}); // To store available units for each project

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await axios.get('http://localhost:5000/project');
      const projects = response.data;

      const availableUnitsMap = {};

      await Promise.all(projects.map(async project => {
        availableUnitsMap[project.project_id] = await fetchAndCalculateTotalUnits(project.project_id);
      }));

      const formattedData = {
        columns: [
          { label: "Project Name", field: "project_name", sort: "asc", width: 150 },
          { label: "City", field: "project_city", sort: "asc", width: 200 },
          { label: "Unit Type", field: "project_unit_type", sort: "asc", width: 270 },
          { label: "Available Units", field: "available_units", sort: "asc", width: 100 },
          { label: "Booking Info", field: "booking_info", sort: "asc", width: 120 },
          { label: "Pricing Range", field: "price_range", sort: "asc", width: 100 },
          { label: "Actions", field: "actions", sort: "asc", width: 100 },
        ],
        rows: projects.map(project => ({
          project_name: project.project_name,
          project_city: project.project_city,
          project_unit_type: project.project_unit_type,
          available_units: availableUnitsMap[project.project_id] || "0",
          booking_info: `Token: ${project.project_token} | CDP: ${project.project_cdp}% - PDP: ${project.project_pdp}%`,
          price_range: `${project.project_price_range} Installment: ${project.project_installment} / Month - ${project.project_months} Months`,
          actions: (
            <div>
              <Link className="text-success" to={`/project-detail/${project.project_id}`} title="Detail info">
                <i className="fa fa-eye fa-lg"></i>
              </Link> &nbsp; &nbsp;
              <i  className="fas fa-trash-alt fa-lg text-danger" onClick={() => handleDelete(project.project_id)} title="Delete info"></i>
            </div>
          ),
        })),
      };

      setData(formattedData);
    } catch (error) {
      console.error('There was an error fetching the project data!', error);
    }
  };

    
    
  // Function to fetch floors and calculate total units
  const fetchAndCalculateTotalUnits = async (project_id) => {
    try {
      const response = await axios.get(`http://localhost:5000/project-floors/${project_id}`);
      const floors = response.data;

      let totalUnits = 0;
      floors.forEach(floor => {
        totalUnits += calculateTotalUnits(floor);
      });

      return totalUnits;
    } catch (error) {
      console.error('Error fetching floors or calculating units:', error);
      return "0"; // Return "N/A" if there's an error
    }
  };
// Utility function to calculate the total units for a floor
const calculateTotalUnits = (floor) => {
  return (
    (floor.floor_detail_kiosk || 0) +
    (floor.floor_detail_shops || 0) +
    (floor.floor_detail_offices || 0) +
    (floor.floor_detail_hotel_apartment || 0 ) +
    (floor.hotel_apartment_quantity|| 0 ) +
    (floor.floor_detail_service_apartment || 0 ) +
    (floor.floor_detail_hs_apartment || 0 ) +
    (floor.floor_detail_apartment || 0 ) +
    (floor.floor_detail_houses || 0 ) +
    (floor.offices_quantity|| 0 ) +
    (floor.floor_detail_villas  || 0)
  );
};


  // Handle project deletion
  const handleDelete = async (projectId) => {
    if (window.confirm("Are you sure you want to delete this project?")) {
      try {
        const response = await axios.delete(`http://localhost:5000/delete-project/${projectId}`);
        
        if (response.status === 200) {
          console.log('Project deleted:', response.data.message);
          alert(response.data.message);
          fetchProjects(); // Refresh the project list
        } else {
          console.error('Error deleting project:', response.data.message);
          alert(`Error: ${response.data.message}`);
        }
      } catch (error) {
        console.error('Request failed:', error);
        alert(`Error: ${error.message}`);
      }
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4">Projects</CardTitle>
              <MDBDataTable responsive striped bordered data={data} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default connect(null, { setBreadcrumbItems })(ProjectTables);

import React, { useEffect, useState } from 'react';
import { Card, CardBody, Label, FormGroup, Input, Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";
import axios from 'axios';

const AddEmployees = (props) => {
    document.title = "Employees | ConnectVU ";

    const breadcrumbItems = [
        { title: "ConnectVU", link: "#" },
        { title: "HR", link: "#" },
        { title: "Employees", link: "#" }, 
        { title: "Add Employees", link: "#" },
    ];

    useEffect(() => {
        props.setBreadcrumbItems('Add Employees', breadcrumbItems);
    }, [props]);

    const [modalOpen, setModalOpen] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [address, setAddress] = useState('');
    const [formVisible, setFormVisible] = useState(false);
    const [disabledFields, setDisabledFields] = useState(false);
    const [departmentName, setDepartmentName] = useState('');
    const [selectLevel, setSelectLevel] = useState('');
    const [companySelect, setCompanySelect] = useState('');

    const toggleModal = () => setModalOpen(!modalOpen);

    const handleSaveChanges = () => {
        // Handle save changes logic here
        console.log(`Company Name: ${companyName}, Address: ${address}`);
        toggleModal();
    };

    const handleSearch = () => {
        if (departmentName && selectLevel && companySelect) {
            setFormVisible(true);
            setDisabledFields(true);
        }
    };

    const handleClear = () => {
        setDepartmentName('');
        setSelectLevel('');
        setCompanySelect('');
        setDisabledFields(false);
        setFormVisible(false);
    };
    const clearForm = () => {
        document.getElementById('title').value = '';
        document.getElementById('fullName').value = '';
        document.getElementById('mobile').value = '';
        document.getElementById('shift').value = '';
        document.getElementById('cnic').value = '';
        document.getElementById('emergencyContact').value = '';
        document.getElementById('gender').value = '';
        document.getElementById('email').value = '';
        document.getElementById('address').value = '';
        document.getElementById('type').value = '';
        document.getElementById('dob').value = '';
        document.getElementById('profilePic').value = '';
        document.getElementById('designations').value = '';
        document.getElementById('maritalStatus').value = '';
        document.getElementById('doj').value = '';
        document.getElementById('reportTo').value = '';
        document.getElementById('monthlyDays').value = '';
        document.getElementById('monthlyHours').value = '';
        document.getElementById('monthlySalary').value = '';
        document.getElementById('monthlySickLeave').value = '';
        document.getElementById('monthlyUrgentLeave').value = '';
        document.getElementById('monthlyShortLeave').value = '';
        document.getElementById('yearlySickLeave').value = '';
        document.getElementById('yearlyUrgentLeave').value = '';
        document.getElementById('yearlyShortLeave').value = '';
        document.getElementById('bankName').value = '';
        document.getElementById('branchName').value = '';
        document.getElementById('accountNumber').value = '';
        document.getElementById('accountName').value = '';
        document.getElementById('swiftCode').value = '';
        document.getElementById('qualificationType').value = '';
        document.getElementById('degreeName').value = '';
        document.getElementById('institutionName').value = '';
        document.getElementById('degreeStartDate').value = '';
        document.getElementById('degreeEndDate').value = '';
        document.getElementById('degree-attachment').value = '';
        document.getElementById('document-attachment').value = '';
    };
    
    const handleSubmit = async () => {
        const employeeData = {
            agent_id: Date.now(), // Just for demonstration, you'd normally generate this in the backend
            company_id: companySelect, // Assuming this is the company ID from the select dropdown
            agent_title: document.getElementById('title').value,
            agent_name: document.getElementById('fullName').value,
            agent_mobile: document.getElementById('mobile').value,
            agent_cnic: document.getElementById('cnic').value,
            agent_marital: document.getElementById('maritalStatus').value,
            agent_emergency_contact: document.getElementById('emergencyContact').value,
            agent_gender: document.getElementById('gender').value,
            agent_email: document.getElementById('email').value,
            agent_address: document.getElementById('address').value,
            department_id: departmentName, // Assuming this is the department ID from the select dropdown
            agent_designations: document.getElementById('designations').value,
            agent_dob: document.getElementById('dob').value,
            agent_doj: document.getElementById('doj').value,
            agent_pic: document.getElementById('profilePic').files[0]?.name || 'default.jpg', // Simplified handling for profile pic
            agent_shift: document.getElementById('shift').value,
            employee_monthly_day: document.getElementById('monthlyDays').value,
            employee_monthly_hours: document.getElementById('monthlyHours').value,
            employee_monthly_salary: document.getElementById('monthlySalary').value,
            employee_monthly_sick_leave: document.getElementById('monthlySickLeave').value,
            employee_monthly_urgent_leave: document.getElementById('monthlyUrgentLeave').value,
            employee_monthly_short_leave: document.getElementById('monthlyShortLeave').value,
            employee_yearly_sick_leave: document.getElementById('yearlySickLeave').value,
            employee_yearly_urgent_leave: document.getElementById('yearlyUrgentLeave').value,
            employee_yearly_short_leave: document.getElementById('yearlyShortLeave').value,
            agent_type: document.getElementById('type').value,
            agent_status_category: selectLevel, // Assuming this is the status category from the select dropdown
            agent_status: 1, // Active status
            agent_timestamp: new Date().toISOString(),
        };
    
        try {
            const response = await axios.post('/api/agents', employeeData);
            if (response.status === 200) {
                alert('Employee added successfully');
                clearForm();
            } else {
                alert('Failed to add employee');
            }
        } catch (error) {
            console.error('Error submitting form', error);
            alert('An error occurred while adding the employee');
        }
    };
    

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <Row className='align-items-center'>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="departmentName">Department Name</Label>
                                <Input
                                    type="select"
                                    id="departmentName"
                                    value={departmentName}
                                    onChange={(e) => setDepartmentName(e.target.value)}
                                    disabled={disabledFields}
                                >
                                    <option value="">Select Department</option>
                                    <option value="Department 1">Department 1</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="selectLevel">Select Level</Label>
                                <Input
                                    type="select"
                                    id="selectLevel"
                                    value={selectLevel}
                                    onChange={(e) => setSelectLevel(e.target.value)}
                                    disabled={disabledFields}
                                >
                                    <option value="">Select Level</option>
                                    <option value="admin">Admin</option>
                                    <option value="employee">Employee</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="companyName">Company Name</Label>
                                <Input
                                    type="select"
                                    id="companyName"
                                    value={companySelect}
                                    onChange={(e) => setCompanySelect(e.target.value)}
                                    disabled={disabledFields}
                                >
                                    <option value="">Select Company</option>
                                    <option value="company1">Company 1</option>
                                    <option value="company2">Company 2</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={3} className='mb-2'>
                            <Button color="primary" onClick={toggleModal} className="mt-3">Add Company</Button>
                        </Col>
                    </Row>

                    <Row >
                        <Col className='d-flex justify-content-center'>

                            <Button
                                color="primary"
                                className="mt-3 me-3"
                                onClick={handleSearch}
                                disabled={!departmentName || !selectLevel || !companySelect}
                            >
                                Proceed
                            </Button>
                            <Button className="mt-3" onClick={handleClear}>Clear</Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            {formVisible && (
                <CardBody>
                    <Card>
                        <CardBody>
                            <h5>Personal Info</h5>
                            <hr />
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="title">Title *</Label>
                                        <Input type="select" id="title">
                                            <option value="Mr">Mr</option>
                                            <option value="Ms">Ms</option>
                                            <option value="Mrs">Mrs</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="fullName">Full Name *</Label>
                                        <Input type="text" id="fullName" placeholder="Enter your full name" />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="mobile">Mobile *</Label>
                                        <Input type="text" id="mobile" placeholder="Enter your mobile number" />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="shift">Shift *</Label>
                                        <Input type="select" id="shift">
                                            <option value="morning">Morning</option>
                                            <option value="evening">Evening</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="cnic">CNIC *</Label>
                                        <Input type="text" id="cnic" placeholder="Enter your CNIC" />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="emergencyContact">Emergency Contact *</Label>
                                        <Input type="text" id="emergencyContact" placeholder="Enter emergency contact number" />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="gender">Gender *</Label>
                                        <Input type="select" id="gender">
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="email">Email Address *</Label>
                                        <Input type="email" id="email" placeholder="Enter your email" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="address">Address *</Label>
                                        <Input type="text" id="address" placeholder="Enter your address" />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="type">Type *</Label>
                                        <Input type="select" id="type">
                                            <option value="fullTime">Full Time</option>
                                            <option value="partTime">Part Time</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="dob">Date of Birth *</Label>
                                        <Input type="date" id="dob" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="profilePic">Profile Pic</Label>
                                        <Input type="file" id="profilePic" />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="designations">Designations *</Label>
                                        <Input type="select" id="designations">
                                            <option value="headOfMarketing">Head of Marketing</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="maritalStatus">Marital Status *</Label>
                                        <Input type="select" id="maritalStatus">
                                            <option value="">Select Marital Status</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="doj">Date of Joining *</Label>
                                        <Input type="date" id="doj" />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="reportTo">Report To *</Label>
                                        <Input type="select" id="reportTo">
                                            <option value="">Select Report To</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <h5>Salary</h5>
                            <hr />
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="monthlyDays">Monthly Days *</Label>
                                        <Input type="number" id="monthlyDays" placeholder="Enter monthly days" />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="monthlyHours">Monthly Hours *</Label>
                                        <Input type="number" id="monthlyHours" placeholder="Enter monthly hours" />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="monthlySalary">Monthly Salary *</Label>
                                        <Input type="number" id="monthlySalary" placeholder="Enter monthly salary" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <h5>Leaves</h5>
                            <hr />
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="monthlySickLeave">Monthly Sick Leave</Label>
                                        <Input type="number" id="monthlySickLeave" placeholder="Enter sick leave days" />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="monthlyUrgentLeave">Monthly Urgent Leave</Label>
                                        <Input type="number" id="monthlyUrgentLeave" placeholder="Enter urgent leave days" />
                                    </FormGroup>
                                </Col>

                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="monthlyShortLeave">Monthly Short Leave</Label>
                                        <Input type="number" id="monthlyShortLeave" placeholder="Enter short leave days" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="yearlySickLeave">Yearly Sick Leave</Label>
                                        <Input type="number" id="yearlySickLeave" placeholder="Enter yearly sick leave days" />
                                    </FormGroup>
                                </Col>

                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="yearlyUrgentLeave">Yearly Urgent Leave</Label>
                                        <Input type="number" id="yearlyUrgentLeave" placeholder="Enter yearly urgent leave days" />
                                    </FormGroup>
                                </Col>


                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="yearlyShortLeave">Yearly Short Leave</Label>
                                        <Input type="number" id="yearlyShortLeave" placeholder="Enter yearly short leave days" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <h5>Bank Details</h5>
                            <hr />
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="bankName">Bank Name</Label>
                                        <Input type="text" id="bankName" placeholder="Enter bank name" />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="branchName">Branch Name</Label>
                                        <Input type="text" id="branchName" placeholder="Enter branch name" />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="accountNumber">Account Number</Label>
                                        <Input type="text" id="accountNumber" placeholder="Enter account number" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="accountName">Account Name</Label>
                                        <Input type="text" id="accountName" placeholder="Enter account name" />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="swiftCode">Swift Code</Label>
                                        <Input type="text" id="swiftCode" placeholder="Enter swift code" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <h5>Qualifications</h5>
                            <hr />
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="qualificationType">Qualification Type</Label>
                                        <Input type="select" id="qualificationType">
                                            <option value="">-- select one --</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="degreeName">Degree Name</Label>
                                        <Input type="text" id="degreeName" placeholder="Enter degree name" />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="institutionName">Institution Name</Label>
                                        <Input type="text" id="institutionName" placeholder="Enter institution name" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="degreeStartDate">Degree Starting Date</Label>
                                        <Input type="date" id="degreeStartDate" />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="degreeEndDate">Degree Completed Date</Label>
                                        <Input type="date" id="degreeEndDate" />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="degree-attachment">Attachment</Label>
                                        <Input type="file" id="degree-attachment" multiple />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <h5>Documents</h5>
                            <hr />
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="document-attachment">Attachment</Label>
                                        <Input type="file" id="document-attachment" multiple />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Row>
                        <Col md={12} className="d-flex justify-content-center mb-4">
                            <Button color="primary" className="me-2 btn-lg" onClick={handleSubmit}>Submit</Button>
                            <Button color="secondary" onClick={clearForm}>Clear</Button>
                        </Col>
                    </Row>
                </CardBody>

            )}

            <Modal isOpen={modalOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Add Company</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="modalCompanyName">Company Name</Label>
                        <Input
                            type="text"
                            id="modalCompanyName"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="modalAddress">Address</Label>
                        <Input
                            type="text"
                            id="modalAddress"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSaveChanges}>Save Changes</Button>
                    <Button color="secondary" onClick={toggleModal}>Close</Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}

export default connect(null, { setBreadcrumbItems })(AddEmployees);

import React, { Component } from 'react';
import {
    Col,
    Card,
    Button
} from "reactstrap";
import './EmployeesCards.css';

class EmployeesCards extends Component {
    render() {
        return (
            <React.Fragment>
                <div className=" d-flex flex-wrap">
                    {
                        this.props.users.map((user, key) =>
                            <div key={key} className="p-0">
                                <Card className="directory-card">
                                    <div>
                                        <div className="directory-bg text-center">
                                            <div className="directory-overlay">
                                                <img className="profile-pic rounded-circle avatar-lg img-thumbnail" src={user.imgUrl} alt="User Avatar" />
                                            </div>
                                        </div>

                                        <div className="directory-content text-start pt-4 px-4 mt-4">
                                            <h5 className="display-6 fs-5 fw-bold text-center my-0">{user.name} | {user.designation}</h5>
                                            <p className="small my-2"><strong>Company:</strong> {user.company}</p>
                                            <p className="small my-2"><strong>Email:</strong> {user.email}</p>
                                            <p className="small my-2"><strong>Phone:</strong> {user.phone}</p>
                                            <p className="small my-2"><strong>Emergency:</strong> {user.emergency}</p>
                                            <p className="small my-2"><strong>Address:</strong> {user.address}</p>
                                        </div>

                                        <div className="text-center pb-4">
                                            <Button color="primary" className="me-1">Rights</Button>
                                            <Button color="success" className="me-1"><i className='mdi mdi-circle-edit-outline'></i></Button>
                                            <Button color="info" className="me-1"><i className='mdi mdi-eye'></i></Button>
                                            <Button className='' color="danger"><i className='mdi mdi-delete'></i></Button>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        )
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default EmployeesCards;

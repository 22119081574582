import React, { useEffect, useState } from 'react';
import { Card, CardBody, Form, FormGroup, Label, Input, Button, Row, Col } from 'reactstrap';
import { setBreadcrumbItems } from 'store/actions';
import { connect } from "react-redux";

const AddClient = (props) => {

    document.title = "Clients | ConnectVU ";

    const breadcrumbItems = [
        { title: "ConnectVU", link: "#" },
        { title: "Marketing", link: "#" },
        { title: "add-client", link: "#" },
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Add Client', breadcrumbItems);
    });

    // Form State
    const [formData, setFormData] = useState({
        fullName: "",
        mobile: "",
        additionalInfo: "",
        cnic: "",
        email: "",
        clientCategory: "",
        clientSource: "",
        referralBy: "",
        address: "",
        remarks: ""
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic
        console.log("Form data submitted:", formData);
    };

    const handleClear = () => {
        setFormData({
            fullName: "",
            mobile: "",
            additionalInfo: "",
            cnic: "",
            email: "",
            clientCategory: "",
            clientSource: "",
            referralBy: "",
            address: "",
            remarks: ""
        });
    };

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <h3>Add Client</h3>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="fullName">Full Name *</Label>
                                    <Input type="text" name="fullName" id="fullName" value={formData.fullName} onChange={handleChange} required />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="mobile">Mobile *</Label>
                                    <Input type="text" name="mobile" id="mobile" value={formData.mobile} onChange={handleChange} required />
                                </FormGroup>
                            </Col>
                        </Row>

                        <h4 className='mt-4'>Additional Information</h4>
                        <hr />

                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="cnic">CNIC</Label>
                                    <Input type="text" placeholder='xxxxx-xxxxxxx-x' name="cnic" id="cnic" value={formData.cnic} onChange={handleChange} />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="email">Email Address</Label>
                                    <Input type="email" placeholder='someone@email.com' name="email" id="email" value={formData.email} onChange={handleChange} />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="clientCategory">Client Category</Label>
                                    <Input type="select" name="clientCategory" id="clientCategory" value={formData.clientCategory} onChange={handleChange}>
                                        <option value="">Open This Select Category</option>
                                        {/* Add more options here as needed */}
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="clientSource">Client Source</Label>
                                    <Input type="select" name="clientSource" id="clientSource" value={formData.clientSource} onChange={handleChange}>
                                        <option value="">Open this select Source</option>
                                        {/* Add more options here as needed */}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="referralBy">Referral By</Label>
                                    <Input type="select" name="referralBy" id="referralBy" value={formData.referralBy} onChange={handleChange}>
                                        <option value="">Open this select Agent</option>
                                        {/* Add more options here as needed */}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="address">Address</Label>
                                    <Input name="address" id="address" value={formData.address} onChange={handleChange} />
                                </FormGroup>
                            </Col>
                        </Row>

                        <FormGroup>
                            <Label for="remarks">Remarks</Label>
                            <Input type="textarea" name="remarks" id="remarks" value={formData.remarks} onChange={handleChange} />
                        </FormGroup>

                        <div className="text-center">
                            <Button color="primary" type="submit" className="me-2">Submit</Button>
                            <Button color="secondary" type="button" onClick={handleClear}>Clear</Button>
                        </div>
                    </Form>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default connect(null, { setBreadcrumbItems })(AddClient);
